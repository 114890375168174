import React from "react"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const WorkshopHegelLesenErfahrung = () => {
  return (
    <Layout backdrop="hegel-hoelderlin">
      <Seo
        title="Hegel lesen mit Veronika Reichl"
        description="Ein Erfahrungsbericht unserer FSJlerin Sara Karanušić"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Hegel lesen mit Veronika Reichl",
              link: "/neuigkeiten/hegel-lesen-erfahrungsbericht",
            },
          ]}
        />
        <PageTitle>Hegel lesen mit Veronika Reichl</PageTitle>
        <Heading level={5}>
          Ein Erfahrungsbericht unserer FSJlerin Sara Karanušić
        </Heading>
        <Paragraph dropcap={true}>
          Obwohl ich einmal gelesen habe, dass Hegel in seinen frühen Jahren ein
          ›Populärphilosoph‹ sein wollte, einer, dessen Philosophie für Alle und
          nicht nur für eine gebildete Elite verständlich ist, tendieren meine
          Erinnerungen an Begegnungen mit Hegel eher in eine entgegengesetzte
          Richtung. Jeder Versuch, in seine Schriften einzudringen, hatte etwas
          Hoffnungsloses - es schien absolut aussichtslos! Die langen, erhabenen
          Sätze voller hermetischer Ausdrücke und komplexer Gedanken waren
          einfach nicht mein Ding.
        </Paragraph>
        <Paragraph>
          Also gab ich auf. Was sollte ich bei einem alten weißen Kerl von vor
          200 Jahren, der über das Universum und die Formen labert schon
          verpassen? Ich lebte in der Glückseligkeit meiner hegelschen
          Ahnungslosigkeit weiter. Bis Veronika Reichl für das Begleitprogramm
          zu unserer aktuellen Sonderausstellung ›Idealismusschmiede in der
          Philosophen-WG‹ von Berlin nach Tübingen kam. Veronika ist eine
          facettenreiche und ungewöhnliche Persönlichkeit – sie bereichert diese
          Welt als Künstlerin, Performerin, Dozentin und Illustratorin. Schon
          Wochen, bevor ich sie kennenlernte, lief ich in unserer
          Sonderausstellung zwischen ihren seltsamen, abstrakten Illustrationen
          umher, ohne auch nur einen Gedanken an Hegel zu verschwenden.
        </Paragraph>
        <Paragraph>
          Hölderlin und Hegel lebten während ihrer Studienzeit zusammen in
          Tübingen. Passend dazu organisierte der Hölderlinturm im Rahmen des
          Literatursommers der Baden-Württemberg-Stiftung eine Serie von
          Abendveranstaltungen unter dem Titel ›Wie sich das Denken
          versprachlicht‹, für die Veronika Reichl zu einer Lesung aus ihren
          Erzählungen und Illustrationen, die als Begleitband zur Ausstellung
          erschienen sind, und einem Workshop eingeladen war. Dabei sollte es um
          Gefühle, Erfahrungen und Ästhetik gehen, nicht unbedingt um den Sinn,
          und es verlangte keine Vorkenntnisse über Hegel, also sagte ich: klar,
          ich habe nichts zu verlieren, außer ein bisschen Geduld!
        </Paragraph>
        <Paragraph>
          Die Lesung fand am 24. September statt und das Interesse wuchs, als
          wir unsere coronabedingten Schutz-Masken abnahmen und unsere Plätze in
          sicherer Entfernung voneinander fanden. Veronika Reichl berichtete
          über die verschiedenen individuellen Hegel-Leseerfahrungen, die sie im
          Laufe der Jahre gesammelt hatte, und stellte vor, wie sie Hegels
          theoretische Texte in Zeichnungen und Geschichten umgesetzt hat.
        </Paragraph>
        <Paragraph>
          Der Workshop am nächsten Tag war meiner Meinung nach noch
          interessanter. Diesmal wagten wir uns selbst an eine experimentelle
          Lektüre von Hegels Texten. Im Sonderausstellungsraum des Turms saßen
          wir in einem Stuhlkreis mit 1,5 Metern Corona-Sicherheitsabstand und
          warteten gespannt darauf, was Veronika Reichl diesmal für uns aus dem
          Ärmel zaubern würde. Wir bekamen einige Exemplare von Hegels
          theoretischem Text ›Subjektiver Geist‹, bunte Eddings, Marker,
          Pastellstifte und Zeichenpapierblätter. Nach jeder Übung umkreisten
          wir Schritt für Schritt denselben Text aus vielen verschiedenen
          Blickwinkeln, da Veronika Reichl mit jeder Übung, die sie mitbrachte,
          immer wieder neue Möglichkeiten eröffnete, sich ihm zu nähern.
        </Paragraph>
        <Paragraph>
          Wir teilten uns in verschiedene Museumsräume auf, lasen den Text für
          uns selbst laut vor und lasen, wie wir es für richtig hielten, ohne
          dem Sinn zu viel Aufmerksamkeit zu schenken. Darum ging es nicht –
          diesmal ging es bei Hegel um die Wahrnehmung, das Gefühl, die
          ästhetische Erfahrung, die sich aus einer bestimmten Abfolge von
          Wörtern ergibt. Ich habe bemerkt, dass ich bestimmte Sätze vorgelesen
          habe, als ob sie etwas wirklich Bedeutsames zu vermitteln hätten,
          etwas Mächtiges und Kraftvolles, obwohl ich die Bedeutung nicht
          verstand oder einige dieser Wörter im Deutschen gar nicht kannte.
        </Paragraph>
        <Paragraph>
          Um die Bewegung des Textes nachzuahmen, überlegten wir uns in
          Zweierteams kleine Performances. Wir haben Ideen in Gesten und Figuren
          übersetzt und Mini-Theaterstücke gestaltet. Wir probierten auf
          verschiedene Vortragsweisen aus, wobei wir jeden Satz immer und immer
          wieder gelesen haben, doch jedes Mal mit Betonung auf anderen Wörtern,
          was uns über den Rhythmus und den Fluss im Text nachdenken ließ. Es
          ist kein Geheimnis, dass das Lesen unverständlicher Texte frustrierend
          sein kann, deshalb lud uns Veronika Reichl dazu ein, in Hegels Text
          alle Sätze und Passagen zu schwärzen, die uns ärgerten, die wir nicht
          mochten oder die Frustration weckten. Dann lasen wir vor, was
          übriggeblieben war.
        </Paragraph>
        <Paragraph>
          Die spannendste Übung von allen blieb für das Finale übrig. Jede*r
          suchte sich eine Stelle im Text aus, nahm ein paar Farben und ein
          Blatt Papier und begann, die Bewegung des Textes auf das Papier zu
          übertragen. Es gibt keine Grenzen und keine Regeln – man geht von
          einem Punkt aus, und man illustriert, ohne zu viel nachzudenken. Sei
          es der Fluss einer bestimmten Wortfolge, vielleicht die Idee, die sich
          durch den Text durchsetzt, die Bewegung der Bedeutung oder des
          Gefühls, während man weiterliest. Ich habe mich von meinem
          Unterbewusstsein leiten lassen – es war fast wie eine freudianische
          Übung, bei der ich dem Pastell erlaubte, meine Hand zu führen, während
          ich diese großen Worte las, ohne anzuhalten, um sie zu verstehen. The
          results were exquisite.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default WorkshopHegelLesenErfahrung
